/* Base Urls */
// export const BASE_URL = 'https://pnh.test.improvement-it.nl/apiv2/';
export const BASE_URL = 'https://backoffice.digitaalvignet.nl';

/* Auth endpoints */
export const LOGIN_ENDPOINT = 'login';

/* User endponts */
export const USERS_ENDPOINT = 'users';

/* Activate endponts */
export const CHECK_PIN_CODE = '/api/CheckPinCode';
export const RESET_PIN_CODE = '/api/ResetPinCode';
export const VESSEL_TYPES = '/api/GetObjectTypes';
export const VESSEL_DETAILS = '/api/GetObject';
export const UPDATE_VESSEL = '/api/UpdateObject';
export const GET_OWNER_DETAILS = '/api/GetOwner';
export const UPDATE_OWNER_DETAILS = '/api/UpdateOwner';
export const UPLOAD_VESSEL_PICTURES = '/api/UploadPictures';

/* Extend endponts */
export const BSN_VERIFICATION_ENDPOINT = '/api/CheckBsn';
export const GET_VIGNET_COST_ENDPOINT = '/api/GetVignetCost';
export const CANCEL_EXTEND_VIGNETTE_ENDPOINT = '/api/CancelVignet';
export const UPDATE_CREDENTIALS = '/api/updatecredentials';
export const GET_PAYMENT_INSTALLMENT = '/api/payment/getinstallments';
export const PAYMENT_BANK_LISTS = '/api/payment/getissuerlist';
export const PAYMENT_MANDATE = '/api/payment/createmandate';
export const PAYMENT_DIRECT = '/api/payment/createdirect';
export const UPDATE_OBJECT_LOCATION = '/api/updateobjectlocation';
export const PAYMENT_HAS_MANDATE = '/api/payment/hasmandate';
export const CREATE_INSTALLMENTS = '/api/payment/createinstallments';

/* BUY VIGNETTE */
export const GET_TARIF_CHOICES_ENDPOINT = '/api-requests/GetRequestDetails';
export const BUY_VIGNET_SIGN_IN_ENDPOINT = '/api-requests/CheckCredentials';
export const BUY_VIGNET_CREATE_ACCOUNT_ENDPOINT =
  '/api-requests/CreateCredentials';
export const BUY_VIGNET_GET_OWNER = '/api-requests/GetOwner';
export const BUY_VIGNET_UPDATE_OWNER = '/api-requests/UpdateOwner';
export const GET_OBJECT_TYPES = '/api-requests/GetObjectTypes';
export const GET_DRIVE_TYPES = '/api-requests/GetDriveTypes';
export const CREATE_DAY_REQUEST = '/api-requests/CreateDayRequest';
export const GET_BANK_LIST = '/api-requests/GetIssuerList';
export const MAKE_DAY_PAYMENT = '/api-requests/MakeDayPayment';
export const GET_OVERVIEW = '/api-requests/GetOverview';
export const UPLOAD_DOCUMENTS = '/api-requests/UploadDocuments';
export const BUY_HAS_MANDATE = '/api-requests/HasMandate';
export const BUY_CREATE_MANDATE = '/api-requests/CreateMandate';
export const BUY_GET_INSTALLMENTS = '/api-requests/GetInstallments';
export const BUY_GET_VIGNET_COST = '/api-requests/GetVignetCost';
export const BUY_CREATE_DIRECT_PAYMENT = '/api-requests/CreateDirect';
export const BUY_CREATE_INSTALLMENTS = '/api-requests/CreateInstallments';
export const BUY_CHECK_DAY_REQUEST = '/api-requests/CheckDayRequest';
export const BUY_CONFIRMATION = '/api-requests/Confirmation';
export const BUY_FORGOT_PASSWORD = '/api-requests/ForgotPassword';
export const BUY_RESET_PASSWORD = '/api-requests/ResetPassword';
