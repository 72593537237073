export default {
  vignette: 'Vignet',
  'vignette.number': 'Vignetnummer',
  'vignette.activationpin': 'Activatiepin',
  lastname: 'Achternaam',
  insertion: 'Tussenvoegsel',
  intial: 'Voorletter(s)',
  address: 'Adres',
  'house.number': 'Huisnummer',
  addition: 'Toevoeging',
  'postal.code': 'Postcode',
  place: 'Plaats',
  'telephone.number': 'Telefoonnummer',
  mobile: 'Mobiel',
  date: 'Datum',

  START: 'BEGIN',
  BACK: 'TERUG',
  NEXT: 'VERDER',
  COMPLETE: 'AFRONDEN',
  UPLOAD: 'UPLOAD',
  CONFIRM: 'BEVESTIGEN',
  yes: 'Ja',
  no: 'Nee',
  'type.vignet': 'Typ Vignet',
  price: 'prijs',
  calculate: 'bereken',
  login: 'Log in',
  'SELECT.PHOTO': 'Selecteer foto',
  filled_in: 'ingevuld',
  fileRequired: 'U moet nog een document uploaden',

  confirmation: 'Bevestiging',
  email: 'Emailadres',
  passwordLabel: 'Wachtwoord',
  forgotPassword: 'Wachtwoord vergeten',
  confirmEmail: 'Bevestig emailadres',
  password: 'Creëer een wachtwoord',
  confirmPassword: 'Bevestig wachtwoord',
  'required.error': 'Dit veld is verplicht. ',
  'email.regex.error':
    'e-mailadres moet de notatie hebben: janedoe@example.com',
  'password.strength.error':
    'Wachtwoord moet bestaan uit: minimaal 8 karakters, tenminste 2 cijfers en een hoofdletter. Maximaal 24 karakters',
  passWordNotMatch: 'Wachtwoord komt niet overeen',
  emailNotMatch: 'email komt niet overeen',
  rate: 'Tarief',
  'payment.method': 'Betalingswijze',
  'personal.details': 'Persoonlijke gegevens',
  'summary.application': 'Samenvatting van uw aanvraag:',
  name: 'Naam',
  bsn: 'BSN',
  day_vignet: 'Dag Vignet',
  vignet_type: 'Soort vignet',
  not_valid_field: 'veld is niet geldig',
  more_than_50_chars: 'De naam mag maximaal 50 tekens bevatten',
  'regular.boat': 'regulier',
  'mileu.boat': 'milieu',
  'abonnement.boat': 'abonnament',
  'help.confirm.this': 'U dient dit te bevestigen',
  electrical: 'Electrisch',
  'buy.vignet.marina.place': 'Plaats in een jachthaven of buiten Amsterdam',
  'length.of.boat': 'Lengte van uw boot',
  'width.of.boat': 'Breedte van uw boot',

  'vessel.name': 'Naam vaartuig',
  'errors.enter.vessel.name': 'Voer de naam van het vaartuig in!',
  'vessel.type': 'Vaartuig type',
  'errors.select.vessel.type': 'Selecteer het type vaartuig!',
  'vessel.data.confirmation':
    'Bevestig dat u de informatie naar waarheid heeft ingevuld',

  'choose.header': 'Maak een keuze',
  'choose.activate': 'Activatie Vignet',
  'choose.extend': 'Verlenging Vignet',

  'activate.header': 'BHG Vignet activeren',
  'activate.subheader':
    'Praktische informatie <subtext>(Let op - u dient dit proces in een keer te doorlopen)</subtext>',
  'activate.instructions.one':
    'Verificatie door nieuw vignetnummer en actiepin',
  'activate.instructions.two':
    'Controleer vaartuig naam en type. Indien nodig pas deze gegevens aan.',
  'activate.instructions.three': 'Controleer persoonlijke gegevens',
  'activate.instructions.four': "Uploaden twee foto's van uw vaartuig",
  'activate.instructions.five': 'Emailbevestiging',
  'activate.instructions.desc.title':
    'U moet de volgende 3 gegevens bij de hand hebben:',
  'activate.instructions.bullets.one': 'Uw nieuwe vignetnummer',
  'activate.instructions.bullets.two': 'De activatiepin',
  'activate.instructions.bullets.three':
    'Een foto van uw volledige vaartuig en een van uw vaartuig met daarop zichtbaar uw vignet aangebracht.',

  'activate.steps.header': 'BHG Vignet activeren',
  'vignette.activation.lostpin': 'Activatiepin verloren? ',
  'vignette.activation.requestpin': 'Vraag hier een nieuwe aan.',
  'vignette.activation.requestpin.success':
    'De nieuwe activatiepin is verzonden naar het emailadres behorend bij dit vignet.',
  'vignette.activation.requestpin.form.text':
    'Geef hier uw vignetnummer op. Er zal een bericht worden gestuurd naar het emailadres dat gelinkt is aan dit vignet.',
  'activate.steps.one.header': 'Verificatie',
  'activate.steps.one.subheader':
    'Vul hier uw niewe vignetnummer en activatiepin in',
  'activate.steps.one.description':
    'Vul hier uw nieuwe vignetnummer en activatiepin in. Het vignetnummer is te vinden op het <bold>nieuwe</bold> fysieke vignet. Als u dit niet heeft genoteerd, raadpleeg dan uw foto’s. De activatiepin is per email naar u verzonden. <bold>Let op:</bold> Vignetten van 2019 of eerder dienen van uw boot te worden verwijderd.',

  'activate.steps.two.header': 'Vaartuiggegevens',
  'activate.steps.two.subheader':
    'Controleer uw vaartuiggegevens. Mochten deze niet correct zijn dan kunt u deze aanpassen.',

  'activate.steps.four.header': 'Uploaden afbeelding',
  'activate.steps.four.subheader':
    "U dient hier twee foto's van uw boot te uploaden.        Klik op 'Selecteer foto' om uw fotos te uploaden",

  'activate.steps.four.distant.title': 'Detail foto',
  'activate.steps.four.distant.description':
    'Kies een foto van uw vaartuig waarop het vignet zichtbaar is.',

  'activate.steps.four.detail.title': 'Foto gehele boot',
  'activate.steps.four.detail.description':
    'Kies een foto waarop uw volledige vaartuig zichtbaar is.',

  'activate.steps.four.confirm.message':
    "De foto's zullen worden gecontroleerd. {break} {break} U bevestigt dat de foto’s de correcte weergave zijn van uw vaartuig.",

  'activate.steps.five.heading': 'Dank u wel!',
  'activate.steps.five.message':
    'Uw vignet is succesvol geactiveerd en er is een bevestigingsmail verzonden.',
  'activate.steps.five.submessage':
    'Gemeente Amsterdam wenst u veel vaarplezier toe!',

  'activate.errors.enter.number': 'Voer hier uw vignetnummer in!',
  'activate.errors.enter.pin': 'Voer hier uw pin in!',
  'activate.errors.pick.picture': 'Kies een foto om verder te gaan!',

  'instruction.extend': 'BHG vignet verlengen/afmelden',

  'extend.start.bold.subTitle.info': 'Praktische informatie',
  'extend.start.light.subTitle.info':
    'Let op ! U dient dit proces in een keer te doorlopen!',
  'extend.start.first.instruction':
    'Verificatie door Burgerservicenummer en huidig vignetnummer',
  'extend.start.second.instruction': 'Controleer vaartuiggegevens en tarief',
  'extend.start.third.instruction': 'Keuze: verlengen of afmelden',
  'extend.start.fourth.instruction':
    'Controleer persoonlijke gegevens en vul waar nodig aan',
  'extend.start.fifth.instruction': 'Maak een BHG-account',
  'extend.start.sixth.instruction':
    'Geef machtiging en kies een betalingsmethode ',
  'extend.start.seventh.instruction': 'Emailbevestiging',

  'form.title': 'BHG vignet',
  'extend.verification': 'Verificatie',
  'extend.verification.number': 'Huidig vignetnummer',
  'extend.verification.bsn': 'Burgerservicenummer',
  'extend.step.verification.contact':
    'Lukt het niet om te verifiëren? Neem dan contact op met de helpdesk',
  'extend.step.verification.contact.number':
    'Openingstijden helpdesk 08:00 - 18:00',
  'extend.verification.instruction':
    'Vul hier uw huidig vignetnummer en Burgerservicenummer in. Het vignetnummer is te vinden op uw huidige vignet of op de verlengingsbrief zoals hieronder aangegeven in de afbeelding. Uw Burgerservicenummer kunt u vinden op uw rijbewijs, paspoort of identiteitskaart. ',
  'extend.rate.title': 'Tarief 2020',
  'extend.rate.instruction':
    'Hieronder vindt u de BHG jaartarieven per vignet. Aan de rechtekant worden uw huidige gegevens getoond en het daarbij behorende tarief for de jaar 2020.',
  'extend.rate.instruction.more':
    'Let op! In de volgende stap krijgt u de keuze om dit vignet te verlengen of af te melden.',
  'extend.rate.description':
    'Mochten de getoonde gegevens niet correct zijn, neem dan contact op met de helpdesk.',
  'extend.rate.type': 'Type Vignet',
  'extend.rate.length': 'Lengte',
  'extend.rate.width': 'Breedte',
  'extend.rate.surface': 'Oppervlakte',
  'extend.rate.cost': 'BHG tarief voor 2020',
  'extend.rate.modal.instruction':
    'Let op! Na ontvangst van het nieuwe vignet zal deze geactiveerd moeten worden.',
  'extend.rate.modal.more.instruction':
    'De opgegeven maten van uw boot zullen gecontroleerd worden.',
  'extend.rate.modal.confirm':
    'Hiermee bevestig ik dat de ingevulde gegevens correct zijn.',
  'extend,rate.modal.enivronment.confirmation':
    'Hiermee bevestig ik dat het vaartuig uitsluitend wordt voortbewogen door spierkracht, wind of elektriciteit',
  'extend.rate.modal.subscription.confirmation':
    'Hiermee bevestig ik dat het vaartuig zich momenteel in een jachthaven bevindt.',

  'extend.rate.renew.button': 'Ik wil mijn vignet verlengen',
  'extend.rate.cancel.button': 'Ik wil mijn vignet afmelden',
  'extend.cancel.signout.title': 'BHG vignet afmelden',
  'extend.signout': 'Afmelden',
  'extend.signout.message':
    'Als u uw vignet afmeldt, hoef u geen binnenhavengeld meer te betalen. U geeft hiermee aan dat u niet meer met uw vaartuig zal afmeren of een ligplaats innemen op de binnenwateren van de Gemeente Amsterdam. Uw oude vignet is niet meer geldig. Wij verzoeken u deze te verwijderen. Vaartuigen zonder geldig vignet worden weggesleept. Wegsleepkosten bedragen €460,00.',
  'extend.signout.consequences':
    'U ontvangt een bevestiging van uw afmelding per email. ',
  'extend.signout.agree': 'Hierbij bevestig ik de afmelding van mijn vignet',
  'extend.signout.terms.link':
    'algemene voorwaarden, Binnenhavengeld verordening en VOB',

  'extend.cancellation.message':
    'U heeft uw vignet succesvol afgemeld en wij hebben een bevestigingsmail naar u verzonden.',

  'extend.step.personal.title': 'BHG vignet verlengen',
  'extend.step.personal.info': 'Persoonlijke gegevens',
  'extend.step.personal.info.checkbox.message':
    'Ik bevestig bovenstaande gegevens correct te hebben ingevuld',
  'extend.step.account': 'Account aanmaken',
  'extend.step.account.information':
    'Hier gaat u uw eigen BHG account aanmaken. Dit account zal u in de toekomst kunnen gebruiken voor  beheer van uw gegegevens en communicatie tussen de gemeente en u.',

  'extend.step.payment.terms.title': 'Betalingsmogelijkheden',
  'instruction.extend.subheader':
    'Praktische informatie <subtext>Let op - u dient dit proces in een keer te doorlopen!</subtext>',
  'extend.step.payment.terms.one':
    'Het betalen van uw vignet kan in één keer via iDEAL of in termijnen via automatische incasso.',
  'extend.step.payment.terms.header.one.title': 'Betalen in een keer',
  'extend.step.payment.terms.header.one.content':
    'Bij betaling in een keer voldoet u het gehele BHG tarief voor 2020 via iDEAL. Voor de daarop volgende jaren kan uitsluitend betaald worden via automatische incasso. U dient hiervoor in de volgende stap een machtiging te geven. Begin 2021 ontvangt u een bericht waarin u verlenging of afmelding kunt aangeven.',
  'extend.step.payment.terms.header.two.title': 'Betalen in termijnen',
  'extend.step.payment.terms.header.two.content':
    'Bij betaling in termijnen wordt het volledige BHG tarief opgesplitst in 2 termijnen, waarvan de eerste termijn (50% van het tarief) direct binnen 48 uur van uw bankrekening wordt afgeschreven. De volgende termijn wordt op 28 april (50%) van uw bankrekeningnummer afgeschreven. Tevens worden € 20 administratiekosten in rekening gebracht.',
  'extend.step.payment.choice.title': 'Tarief BHG 2020',
  'extend.step.payment.choice.full': ' Betalen in een keer',
  'extend.step.payment.choice.installment': ' Betalen in termijnen ',
  'extend.step.payment.choice.admin.cost': 'inclusief € 20 administratiekosten',

  'extend.step.payment.full.instruction.one':
    'Voor het jaar 2020 betaalt u het tarief direct door een IDEAL betaling. Het betalen van dit tarief houdt in dat u vanaf 1 januari 2020 tot 31 december 2020 een ligplaats mag innemen op het binnenwater van de Gemeente Amsterdam.',
  'extend.step.payment.full.instruction.two':
    'De daarop volgende jaren zal het tarief automatisch van uw rekening worden afgeschreven. U ontvangt begin volgend jaar bericht over de nieuwe tarieven en de mogelijkheid tot aan/afmelden.',
  'extend.step.payment.info.agreement':
    'Hierbij bevestig ik akkoord te gaan met de',
  'extend.step.payment.terms':
    'algemene voorwaarden,Binnenhavengeld verordening en VOB',
  'extend.step.payment.terms.condition': 'Algemene voorwaarden',
  'extend.step.payment.successful':
    'Uw vignet is succesvol verlengd. U ontvangt een bevesting per email.',

  'extend.step.payment.installment.title': 'Betalen in termijnen',
  'extend.step.payment.installment.info':
    'Voor het betalen in termijnen geldt een automatisch incasso. Het betalen van dit tarief houdt in dat u vanaf 1 januari 2020 tot 31 december 2020 een ligplaats mag innemen op het binnenwater van de Gemeente Amsterdam. Het volledige BHG tarief wordt opgesplitst in 2 termijnen, waarvan de eerste termijn (50% van het tarief) direct binnen 48 uur van uw bankrekening wordt afgeschreven. De volgende  termijn wordt op 28 april (50%) van uw bankrekening afgeschreven. Bovenop het tarief worden er eenmalig administratiekosten a € 20 berekend en verdeeld over de termijnen.',
  'extend.step.payment.installment.info.two':
    'U betaalt uw BHG tarief verspreid over deze termijnen',
  'extend.step.payment.within.hour': ' binnen 48 uur',
  'extend.step.payment.march': '28 maart',
  'extend.step.payment.april': '28 april',

  'extend.step.payment.bank.mandate': 'Machtiging - Kies uw bank',
  'extend.step.payment.select.bank': 'Selecteer een bank',
  'extend.step.payment.bank.ideal': 'iDEAL -  Kies uw bank',

  'extend.step.vignette.rate.hover.message': 'hover message',
  'extend.step.vignette.rate.second.hover.message':
    'U mag dag en nacht een ligplaats innemen in Amsterdam ',
  'extend.step.vignette.rate.third.hover.message':
    '70% milieukorting - uw vaartuig is emmissievrij, aangedreven door spierkracht, wind of elektriciteit. U mag dag en nacht een ligplaats innemen in Amsterdam.',
  'extend.step.vignette.rate.fourth.hover.message':
    '50% korting - Uw vaartuig heeft momenteel een ligplaats in een jachthaven of buiten Amsterdam. U mag alleen overdag (tussen 07.00 en 23.00 uur) een ligplaats innemen in Amsterdam.',

  'extend.step.rate.marina.name': 'Naam jachthaven',
  'extend.step.terms.and.condition': 'algemene voorwaarden',
  'extend.step.terms.and.condition.message': `<p><strong>ALGEMENE VOORWAARDEN BETALING BINNENHAVENGELD PLEZIERVAART 2020</strong></p>
  <ul>
    <li><b>Definities</b></li>
    <li>“Binnenhavengeld Pleziervaart”: het tarief dat wordt geheven voor het recht om met een pleziervaartuig gebruik te maken van het openbaar water; </li>
    <li>“Pleziervaartuig”: een vaartuig dat in tijd nagenoeg geheel wordt gebruikt voor de niet-bedrijfsmatige varende recreatie;</li>
    <li>“Openbaar water”: alle wateren gelegen binnen de gemeentegrenzen van Amsterdam die al of niet met enige beperking voor het publiek bevaarbaar of anderszins toegankelijk zijn; </li>
    <li>“Vignet”: vignet dat op een duidelijk zichtbare plaats op de achterzijde van het pleziervaartuig is bevestigd en waarmee kan worden vastgesteld of het Binnenhavengeld Pleziervaart is voldaan voor het pleziervaartuig waarop het is bevestigd; </li>
    <li>“Eigenaar”: degene die op grond van artikel 3 van de Verordening Binnenhavengeld Pleziervaart 2020 belastingplichtig is;</li>
    <li>“Schipper”: degene die de feitelijke leiding over een pleziervaartuig voert;</li>
    <li>Voor de overige in deze algemene voorwaarden gebruikte definities wordt verwezen naar de begripsbepalingen als genoemd in artikel 1 van de Verordening Binnenhavengeld Pleziervaart 2020.</li>
  </ul>
  <br />
  <ul>
  <li><b>Toepasselijkheid </b></li>
  <li>Deze algemene voorwaarden zijn van toepassing op de betaling van het Binnenhavengeld Pleziervaart als bedoeld in artikel 2 van de Verordening Binnenhavengeld Pleziervaart 2020. </li>
  <li>Alle verplichtingen die voortvloeien uit de Verordening Binnenhavengeld Pleziervaart 2020 en de Verordening op het Binnenwater 2019 zijn onverkort van toepassing. </li>
  </ul>
  <br />
  <ul>
  <li><b>Tarieven</b></li>
  <li>De verschillende tarieven komen overeen met de in artikel 5 van de Verordening Binnenhavengeld Pleziervaart 2020 genoemde bedragen.</li>
  <li>Het tarief van het Binnenhavengeld Pleziervaart is verschuldigd voor het gehele kalenderjaar, ongeacht het moment van aanvang.</li>
  <li>De eigenaar van een pleziervaartuig krijgt aan het begin van het kalenderjaar bericht over het op dat moment geldende tarief.</li>
  </ul>
  <br />
  <ul>
  <li><b>Voorwaarden</b></li>
  <li>De eigenaar van een pleziervaartuig wordt geacht: </li>
    <ul>
    <li>alle benodigde persoonsgegevens te verstrekken, waaronder in ieder geval worden begrepen diens naam, adresgegevens, emailadres en telefoonnummer;</li>
    <li>alle benodigde gegevens van het pleziervaartuig te verstrekken, waaronder in ieder geval worden begrepen de naam, de afmetingen en de wijze van aandrijving; </li>
    <li>foto’s van het pleziervaartuig ter beschikking te stellen; en</li>
    <li>indien van toepassing, een jachthavendocument en de naam van de jachthaven te verstrekken, ten behoeve van het bepalen van het abonnementstarief. </li>
    </ul>
  <li>De eigenaar van een pleziervaartuig gaat akkoord met de verwerking van de in het eerste lid genoemde persoonsgegevens en de gegevens van het pleziervaartuig ten behoeve van registratiedoeleinden. </li>
  <li>De eigenaar van een pleziervaartuig gaat akkoord met de verwerking van de in het eerste lid genoemde persoonsgegevens en gegevens van het pleziervaartuig door camera’s en sensoren die zich bevinden op en bij het openbaar water. Het Amsterdams Beleidskader Cameratoezicht is onverkort van toepassing.</li>
  <li>De eigenaar van een pleziervaartuig is verplicht eventuele wijzigingen van de in het eerste lid genoemde gegevens onverwijld door te geven aan de gemeente Amsterdam. </li>
  <li>Indien de eigenaar van een pleziervaartuig doelbewust verkeerde gegevens, als bedoeld in het eerste lid, heeft verstrekt, wordt het vignet voor dat pleziervaartuig ongeldig verklaard en verliest de eigenaar het recht om met dat pleziervaartuig gebruik te maken van het openbaar water of daar direct of indirect af te meren. </li>
  </ul>
  <br />
  <ul>
  <li><b>Vaarbewijs</b></li>
  <li>De schipper van een pleziervaartuig beschikt over een Vaarbewijs 1, indien:</li>
    <ul>
      <li>dat pleziervaartuig harder kan dan 20 kilometer per uur door het water;</li>
      <li>dat pleziervaartuig een lengte heeft van 15 meter tot 25 meter.</li>
    </ul>
  </ul>
  <br />
  <ul>
    <li><b>Betaling</b></li>
    <li>De eigenaar van een pleziervaartuig is het Binnenhavengeld Pleziervaart verschuldigd bij aanvang van het kalenderjaar. </li>
    <li>Betaling van het Binnenhavengeld Pleziervaart vindt uitsluitend plaats via:</li>
    <li>iDeal, indien het verschuldigde tarief in een keer wordt voldaan;</li>
    <li>automatische incasso, indien het verschuldigde tarief voor 1 mei van het kalenderjaar in verschillende maandtermijnen wordt voldaan.</li>
    <li>Bj betaling in termijnen als bedoeld in het tweede lid, onderdeel b, worden extra administratie- en incassokosten in rekening gebracht.</li>
    <li>Indien betaling in termijnen als bedoeld in het tweede lid, onderdeel b, niet meer kan worden gerealiseerd, kan de eigenaar van een pleziervaartuig een betalingsregeling aanvragen. De door de gemeente Amsterdam gehanteerde voorwaarden bij het treffen van een betalingsregeling zijn onverkort van toepassing. </li>
  </ul>
  <br />
  <ul>
  <li><b>Termijn </b></li>
  <li>Het Binnenhavengeld Pleziervaart moet volledig zijn betaald voor 1 maart van het lopende kalenderjaar.</li>
  <li>Indien het Binnenhavengeld Pleziervaart in verschillende maandtermijnen wordt betaald, moet het Binnenhavengeld Pleziervaart volledig zijn betaald voor 1 mei van het lopende kalenderjaar. </li>
  <li>ndien het Binnenhavengeld Pleziervaart niet volledig is betaald voor de in het eerste of tweede lid genoemde datum, ontvangt de eigenaar van het pleziervaartuig eenmalig een herinnering waarin hij wordt verzocht het Binnenhavengeld Pleziervaart onverwijld te voldoen. </li>
  <li>Indien het Binnenhavengeld Pleziervaart niet volledig is betaald voor de in het eerste of tweede lid genoemde datum, raakt de eigenaar van het pleziervaartuig terstond in verzuim zonder dat daarvoor een nadere sommatie of ingebrekestelling is vereist. </li>
  </ul>
  <ul>
  <li><b>Incassokosten</b></li>
  <li>Indien de eigenaar van het pleziervaartuig in gebreke of in verzuim is ten aanzien van de betaling van het Binnenhavengeld Pleziervaart, komen alle redelijke kosten ter verkrijging van voldoening buiten rechte voor rekening van de eigenaar van het pleziervaartuig.</li>
  <li>Indien de eigenaar van het pleziervaartuig in gebreke blijft ten aanzien van tijdige voldoening van het Binnenhavengeld Pleziervaart, volgt een naheffingstoeslag van 50% over het nog verschuldigde Binnenhavengeld Pleziervaart. De naheffingstoeslag ontslaat de eigenaar van het pleziervaartuig niet van de verplichting om het volledige Binnenhavengeld Pleziervaart alsnog te voldoen. Evenmin wordt de naheffingstoeslag in mindering gebracht op het verschuldigde Binnenhavengeld Pleziervaart. </li>
  <li>Eventuele gemaakte gerechtelijke en executiekosten komen voor rekening van de eigenaar van het pleziervaartuig. </li>
  <li>De eigenaar van het pleziervaartuig is over de gemaakte incassokosten rente verschuldigd.</li>
  </ul>
  <ul>
  <li><b>Afmelden</b></li>
  <li>Indien de eigenaar van een pleziervaartuig afziet van betaling van het Binnenhavengeld Pleziervaart, dan geeft hij dat aan voor aanvang van het nieuwe kalenderjaar. </li>
  <li>In het geval als bedoeld in het eerste lid wordt het vignet voor dat pleziervaartuig met ingang van het nieuwe kalenderjaar ongeldig verklaard en verliest de eigenaar het recht om met dat pleziervaartuig gebruik te maken van het openbaar water of daar direct of indirect af te meren. </li>
  <li>In het geval als bedoeld in het eerste lid verwijdert de eigenaar met ingang van het nieuwe kalenderjaar het vignet van het pleziervaartuig en retourneert hij het vignet aan de gemeente Amsterdam. </li>
  </ul>
  <br />
  <ul>
  <li><b>Restitutie</b></li>
  <li>Er vindt geen restitutie plaats van het voor dat kalenderjaar verschuldigde Binnenhavengeld Pleziervaart.</li>
  </ul>
  <br />
  <ul>
  <li><b>Gedeeltelijke verwerping van algemene voorwaarden </b></li>
  <li>Indien één of meer van de bepalingen van deze algemene voorwaarden door rechterlijke tussenkomst worden verworpen, dan blijven de resterende bepalingen van deze algemene voorwaarden van toepassing. </li>
  </ul>
  `,

  'extend.step.port.rules': 'Binnenhavengeld verordening',
  'extend.step.port.rules.message': 'Inland port dues regulation message',
  'extend.step.vob.message': 'VOB message',
  'extend.step.payment.mandate.success.message':
    'Het afgeven van de machtiging is gelukt',

  'extend.step.payment.mandate.cancel.message':
    'De machtiging was niet gelukt. Probeer het opnieuw of neem contact op met uw bank.',
  'extend.step.payment.mandate.error.message':
    'De machtiging was niet gelukt. Probeer het opnieuw of neem contact op met uw bank.',
  'extend.step.payment.mandate.reject.message':
    'De machtiging was niet gelukt. Probeer het opnieuw of neem contact op met uw bank.',

  'extend.step.payment.direct.cancel.message':
    'De betaling is niet gelukt. Probeer het opnieuw.',
  'extend.step.payment.direct.error.message':
    'De betaling is niet gelukt. Probeer het opnieuw.',
  'extend.step.payment.direct.reject.message':
    'De betaling is niet gelukt. Probeer het opnieuw.',

  'extend.instructions.bullets.one': 'Uw BSN nummer',
  'extend.instructions.bullets.two': 'Uw huidige vignetnummer',
  'extend.instructions.bullets.three': 'De maten van uw boot',

  'extend.hotdesk.line':
    'Tel:14020 (vanuit buitenland +3120624111), Email: vaarvignet@amsterdam.nl',
  'extend.abonnment.vignette.message':
    'NB: als u in 2020 geen vignet meer nodig heeft, vink deze toch aan en vul bij Naam jachthaven “Afmelding” in.',
  'extend.payment.instruction.more.context':
    'In het volgende scherm regelt u de digitale machtiging.',
  'extend.customer.addition.popover.message': 'addition popover message',
  'extend.installment.period': 'Termijn',
  'extend.installment.amount': 'Bedrag',
  'extend.installmennt.date': 'Datum',
  'extend.installment.48hours': 'binnen 48 uur',
  'extend.installment.march': '28 maart',
  'extend.installment.april': '28 april',
  'extend.bank.not.listed':
    'Als uw bank niet in de lijst staat vermeld neemt u dan contact op met 14020.',
  'extend.bank.authorization':
    'Als het een en/en rekening betreft, is een machtiging nodig van alle partijen.',
  'extend.installment.table.info':
    'De daarop volgende jaren zal het tarief automatisch van uw rekening worden afgeschreven. U ontvangt begin volgend jaar bericht over de nieuwe tarieven, de betaalmogelijkheden en de mogelijkheid tot aan/afmelden',
  'extend.instllament.no.installment':
    'Betaling in termijnen niet meer mogelijk na 25 maart',
  'browser.not.supported':
    'Om deze website te kunnen bezoeken dient u gebruik te maken van één van de volgende webbrowsers: Chrome, FireFox, Safari and Edge',

  'buy.vignet': 'Vignet aanvragen',
  'BHG.vignet': 'Binnenhavengeldvignet',
  'mooring.vignet': 'Ik wil een dag aanmeren',
  'buy.vignete.instruction.title': 'Binnenhavengeldvignet aanvragen',
  'buy.vignet.instruction.one.day.title': 'Vignet aanvragen voor 1 dag',
  'buy.vignete.instruction.content':
    'Hieronder ziet u welke stappen u doorloopt in dit formulier. <br /> En hoe lang het duurt om het formulier in te vullen. ',
  'buy.vignet.instruction.subtitle':
    '<subtext>Let op: uw gegevens worden niet opgeslagen tot de aanvraag is afgerond!</subtext>',
  'buy.vignet.instruction.contact':
    'Moeilijkheden tijdens het proces? Neem dan contact op met de helpdesk <br /> Openingstijden helpdesk 08:00 - 18:00 <br /> Tel:14020 (vanuit buitenland +3120624111), Email: vaarvignet@amsterdam.nl',
  'buy.vinget.instruction.item.1': 'Prijsberekening van uw vignet',
  'buy.vinget.instruction.item.2': 'Account aanmaken of inloggen',
  'buy.vinget.instruction.item.3': 'Documenten uploaden (indien nodig)',
  'buy.vinget.instruction.item.4': 'Controleer uw gegevens',
  'buy.vinget.instruction.item.5':
    'Machtiging afgeven voor automatische incasso',
  'buy.vinget.instruction.item.6': 'Kies hoe u wilt betalen',
  'buy.vinget.instruction.item.7': 'Betalen',
  'buy.vinget.instruction.one.day.item.1': 'Vul uw persoonlijke gegevens in',
  'buy.vinget.instruction.one.day.item.2': 'Vul uw bootgegevens in',
  'buy.vinget.instruction.one.day.item.3': 'Controleer uw gegevens',
  'buy.vinget.instruction.one.day.item.4': 'Vignet betalen',

  'buy.vignet.personal.info.form.title': 'Vul uw persoonlijke gegevens in',
  'buy.vignet.boat.name': 'Naam van uw boot',
  'buy.vignet.boat.type': 'Type boot',
  'buy.vignet.boat.propulsion': 'Aandrijving van uw boot',
  'buy.vignet.info.title': 'BHG-informatie',
  'regular.boat.description': 'Regular boat description',
  'mileu.boat.description': 'Mileu boat description',
  'abonnement.boat.description': 'Abonnement boat description',
  'buy.vignet.tarif.choices.title': 'Prijsberekening van uw vignet',
  'buy.vignet.tarif.choices.instruction.title':
    'Om de prijs van uw vignet te berekenen, hebben wij wat informatie van u nodig. ',
  'buy.vignet.tarif.choices.instruction.description':
    'Hoe wordt uw boot aangedreven?',
  'buy.vignet.tarif.boat.option.electrical':
    'Door een elektrische motor of zonder motor',
  'buy.vignet.tarif.boat.option.other': 'Door een benzinemotor of dieselmotor',
  'buy.vignet.tarif.boat.option.both':
    'Door elektrische motor én een benzine- of dieselmotor',
  'buy.vignet.tarif.boat.size': 'Hoe groot is uw boot?',
  'buy.vignet.tarif.place.label':
    'Ligt uw boot in een jachthaven, buiten Amsterdam of in gepacht water?',
  'buy.vignet.tarif.boat.surface': 'Oppervlakte van uw boot',
  'buy.vignet.document.tooltip':
    'Ligt uw boot in een jachthaven in of buiten Amsterdam? <br />Dan moet u een jachthavenfactuur uploaden. <br />Ligt uw boot buiten Amsterdam, maar niet in een jachthaven? <br />Dan moet u een foto van de ligplaats uploaden met daarop zichtbaar de boot afgemeerd. <br />En een kaart van de locatie waar uw boot ligt.',
  'buy.vignet.click.here': 'Klik hier',
  'buy.vignet.tarif.modal.title': 'Let op: u vraagt een wit vignet aan',
  'buy.vignet.tarif.modal.hybride.title':
    'Let op: u vraagt een geel vignet aan',
  'buy.vignet.tarif.modal.hybdride.description':
    'Uit uw gegevens blijkt dat u een geel vignet gaat aanvragen. Dat is een vignet waarbij uw boot een elektrische motor én een benzine- of dieselmotor heeft. Met dit vignet bent u verplicht uw elektrische motor te gebruiken als u in Amsterdam vaart. mag uw elektrische motor tijdens het varen niet opladen met een (brandstof) generator. Wilt u in Amsterdam uw benzine- of dieselmotor gebruiken? Sluit dan dit scherm en wijzig uw gegevens.',
  'buy.vignet.tarif.modal.description':
    "Uit uw gegevens blijkt dat u een wit vignet gaat aanvragen. Dat is een vignet waarbij u in een jachthaven of buiten Amsterdam ligt. U moet hiervoor later in het formulier een bewijs uploaden. Met dit vignet mag u tussen 23.00 en 06.00 uur niet afmeren in Amsterdam. Wilt u wel 's nachts afmeren? Sluit dan dit scherm en wijzig uw gegevens.",
  'buy.vignet.tarif.modal.milieu.description':
    'Uit uw gegevens blijkt dat u een groen vignet gaat aanvragen. Dit vignet is alleen geldig als uw boot een elektrische motor of geen motor heeft. Heeft uw boot een benzine- of dieselmotor? Sluit dan dit scherm en wijzig uw gegevens.',
  'buy.vignet.account.description':
    'Hebt u al eerder een vignet aangevraagd of verlengd? Dan hebt u al een account. <br />Is dit uw eerste aanvraag? Dan moet u een account aanmaken.',
  'buy.vignet.have.account': 'ik heb al een BHG-account',
  'buy.vignet.no.account': 'Ik heb nog geen BHG account',
  'upload.files': 'Documenten uploaden',
  'upload.files.instruction': 'U moet hier documenten toevoegen.',
  'upload.files.message': 'Sleep de document(en) hierheen of selecteer uw ',
  'upload.files.select.files': 'document(en)',
  'request.overview': 'Controleer uw gegevens',
  'request.overview.title': 'Dit zijn de gegevens die wij van u hebben:',
  'request.overview.vignet.type': 'Type vignet',
  'request.overview.drive': 'Aandrijving',
  'request.overview.hunting': 'Jachtavens',
  'request.overview.night': 'Night aanmeren',
  'request.overview.amount.toPay': 'Bedrag dat u moet betalen',
  'request.overview.whitevignet.timeswarning':
    'Met dit vignet mag u alleen afmeren tussen 06.00 uur en 23.00 uur!',
  'request.overview.checkbox.message':
    'Ik heb alle gegevens correct en volledig ingevuld. Ik begrijp dat een aanvraag via internet dezelfde juridische status heeft als een aanvraag met een geschreven geldige handtekening.',
  'request.overview.checkbox.message.2':
    'Ik ben op de hoogte van de regels die op het water van Amsterdam gelden, zoals ze staan beschreven in de Binnenhavengeldverordening Pleziervaart en de Verordening op het binnenwater (te vinden op amsterdam.nl/varen). Bij het verlengen of opzeggen van mijn vignetten ga ik met deze regels akkoord.',
  'buy.one.day.vignet.successful.message':
    'Wij hebben het digitale vignet <br /> naar uw e-mailadres gestuurd',
  'buy.vignet.successful.message':
    'Uw aanvraag met nummer <span style="color: #2699FB">{variable}</span> is goed ontvangen. <br />Wij controleren eerst de documenten die u hebt geüpload. <br />Als uw documenten zijn goedgekeurd, <br />krijgt u een e-mail met meer informatie over het betalen.',
  'buy.vignet.success.no.documents.message':
    'Uw aanvraag met nummer <span style="color: #2699FB">{variable}</span> is goed ontvangen. <br />U krijgt van ons een e-mail met meer informatie.',
  'buy.vignet.document.info.title':
    '<strong>U moet document(en) uploaden</strong>',
  'buy.vignet.document.info':
    'Om dit jaarvignet aan te vragen, moet u verderop in het formulier documenten uploaden. Zorg ervoor dat u deze document(en) bij de hand hebt. <br /> Als u deze documenten niet hebt, kunt u de aanvraag niet afronden.',
  'buy.vignet.tarif.choices.documents.info': 'Some messages',
  'buy.one.day.vignet.date.restriction':
    'U kunt het vignet alleen aanvragen op de dag dat u ook gaat afmeren.<br /> Het vignet is dan geldig van 00.01 tot en met 23.59 uur. <br /> Het is niet mogelijk om een dag in de toekomst te kiezen. <br /> Let op: uw boot moet korter zijn dan 10  meter.',
  'buy.vignet.tarif.modal.checkbox':
    'Ik heb de waarschuwing gelezen en ga akkoord.',
  'buy.vignet.payment.instruction.description':
    ' U kunt kiezen om uw vignet in 1 keer te betalen via iDEAL of in delen via automatische incasso. U moet hiervoor een machtiging geven. Met de machtiging geeft u de gemeente Amsterdam toestemming om ieder jaar de kosten voor het vignet van uw rekening af te schrijven. U ontvangt hierover jaarlijks bericht.',
  'buy.vignet.payment.instruction.date': 'Betalen in 2021',
  'buy.vignet.payment.instruction.title': ' ',
  'buy.vignet.payment.instruction.date.content': ' ',
  'buy.vignet.payment.instruction.date.content.more':
    'Begin januari 2021 krijgt u van ons een brief met daarin informatie over de nieuwe tarieven. Wilt u uw vignet in 2021 verlengen? Dan krijgt u ook dan de keus om het bedrag in 1 keer te betalen of in termijnen. U kunt uw vignet natuurlijk ook afmelden. <br />Er wordt dan geen geld van uw rekening afgeschreven.',
  'buy.vignet.payment.instruction.issue': 'Machtiging afgeven',
  'buy.vignet.payment.instruction.issue.content':
    'In het volgende scherm geeft u een machtiging aan uw bank. Daarna gaat u verder met de aanvraag van uw jaarvignet van 2020.',
  'buy.vignet.payment.choice.description':
    'U kunt uw jaarvignet in 1 keer betalen via iDeal of in termijnen via automatische incasso. Kies hieronder hoe u wilt betalen.',
  'buy.vignet.payment.choice.description.disable':
    'Vanaf 29 september is het alleen nog mogelijk om uw jaarvignet 2020 in 1 keer te betalen.',
  'buy.vignet.payment.once': 'In 1 keer betalen',
  'buy.vignet.payment.installment': 'In termijnen betalen',
  'buy.vignet.payment.modal.description':
    'Let op: moeten wij nog documenten controleren of kiest u voor Betalen in termijnen? Dan is uw aanvraag hierna direct afgerond.<br />U kunt dan niet meer terug en ontvangt later een e-mail met daarin informatie over het betalen.',
  'buy.vignet.account.creation.instruction': 'U gaat nu een account aanmaken.',
  'buy.vignet.upload.documents.lig.info': `<h4><strong>Als uw boot in een jachthaven in of buiten Amsterdam ligt</strong></h4><span>Het jachthavenbewijs is een factuur van de jachthaven. Hierin moet staan dat u het jachthavengeld voor dat jaar moet betalen (jaarliggeld mm-jj tot en met mm-jj). Op de factuur moet ook staan:</span>
                                          <ul>
                                          <li>de naam van de jachthaven</li>
                                          <li>het adres van de jachthaven</li>
                                          <li>de datum van de factuur</li>
                                          <li>uw eigen adres</li>
                                          </ul>
                                          <h4>Als uw boot buiten Amsterdam en niet in een jachthaven ligt</h4>
                                          <span>Foto van de ligplaats met daarop zichtbaar de boot afgemeerd en een kaart van de locatie</span>
                                          
                                          <br>                                         
                                          <h4>Als uw boot in water ligt dat u pacht</h4>
                                          <span>Geldig erfpachtcontract. 
                                          </span>

                                          
`,
  'buy.vignet.tarif.milieu.choice.modal.title':
    'Let op: u vraagt een groen vignet aan',
  'buy.vignet.forgot.password.title':
    'Geef hier uw emailadres op. Er zal een bericht worden gestuurd naar het emailadres dat gelinkt is aan dit vignet.',
  'buy.vignet.make.password': 'Maak een wachtwoord',
  'buy.vignet.retype.password': 'Herhaal uw wachtwoord',
  'buy.vignet.reset.password': 'Wachtwoord resetten',
  'buy.vignet.hereby.confirm':
    'Ik bevestig hierbij dat ik de gegevens correct heb',
  'not.supported.now':
    'Het is op dit moment niet mogelijk om een dagvignet aan te vragen. Voor meer informatie zie  <a href="https://amsterdam.nl/varen">amsterdam.nl/varen</a>',
  'annual.instruction.description': `<h4>Privacy</h4>
  De gemeente Amsterdam gaat zorgvuldig en veilig met uw persoonsgegevens om. <br />Wij houden ons daarbij aan de privacywetten en -regels. Dat betekent bijvoorbeeld dat wij uw gegevens alleen voor deze aanvraag gebruiken en niet voor iets anders. <br /> Meer informatie vindt u in de <a href="https://www.amsterdam.nl/privacy/specifieke/privacyverklaring-parkeren-verkeer-bouw/verkeersmanagement-gracht-privacy/" target="_blank">privacyverklaring Binnenhavengeld</a>.`,
};
