import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Row, Col, Alert, Form } from 'antd';
import { FormattedMessage } from 'react-intl';
import moment from 'moment';

import Title from 'components/Title';
import { EXTEND_VIGNETTE_ROUTE } from 'constants/index';
import './styles.scss';
import StepCard from 'components/StepCard';
import { history } from 'util/helpers/history';
import { formatAmountToNL } from 'util/helpers/normalizer';

let timer;

class PaymentChoice extends Component {
  state = {
    visible: true,
  };
  componentDidMount() {
    this.props.getInstallmentData();
    history.location.state = {};
  }

  getVignetCost = () => {
    if (this.props.totalPayment && this.props.totalPayment.vignet_cost) {
      return formatAmountToNL(this.props.totalPayment.vignet_cost);
    } else {
      try {
        return formatAmountToNL(
          JSON.parse(sessionStorage.getItem('cost')).vignet_cost,
        );
      } catch (e) {}
    }
  };

  canClickInstallment = () => {
    // months are zero-indexed
    const today = moment();
    if (today.month() < 2) {
      return false;
    } else if (today.month() == 2 && today.date() <= 25) {
      return false;
    }

    return false;
  };

  renderSuccessMessage = () => {
    clearTimeout(timer);
    timer = setTimeout(() => {
      this.setState({ visible: false });
    }, 5000);
    if (this.state.visible) {
      return (
        <div className="spacing">
          <Alert
            type="success"
            message={
              <FormattedMessage id="extend.step.payment.mandate.success.message" />
            }
          />
        </div>
      );
    }
  };

  renderInstallment = () => {
    const { installment } = this.props;
    return (
      <React.Fragment>
        <img
          alt="cancel vignette"
          className="main-icons"
          src={require('assets/part.svg')}
          onClick={() =>
            this.props.updateRoute(EXTEND_VIGNETTE_ROUTE.PART_PAYMENT_AGREEMENT)
          }
        />
        <p className="payment">
          <FormattedMessage id="extend.step.payment.choice.installment" />
        </p>
        {installment &&
        installment.installments &&
        installment.installments.length ? (
          <>
            <p>
              <span className="amount">
                &#8364;&nbsp;
                {installment.installments.reduce((a, b) =>
                  formatAmountToNL(a + b),
                )}
              </span>{' '}
              (
              <span>
                <FormattedMessage id="extend.step.payment.choice.admin.cost" />
              </span>
              )
            </p>
            <div className="installment-wrapper">
              <table border={1} className="installment-table">
                <tr>
                  <th className="payment-installment-header">
                    <FormattedMessage id="extend.installment.period" />
                  </th>
                  <th className="payment-installment-header">
                    <FormattedMessage id="extend.installment.amount" />
                  </th>
                  <th className="payment-installment-header">
                    <FormattedMessage id="extend.installmennt.date" />
                  </th>
                </tr>
                <tr>
                  <td>1</td>
                  <td>
                    &#8364;&nbsp;{formatAmountToNL(installment.installments[0])}
                  </td>
                  <td>
                    <FormattedMessage id="extend.installment.48hours" />
                  </td>
                </tr>
                <tr>
                  <td>2</td>
                  <td>
                    &#8364;&nbsp;{formatAmountToNL(installment.installments[1])}
                  </td>
                  <td>
                    <FormattedMessage id="extend.installment.april" />
                  </td>
                </tr>
              </table>
            </div>
          </>
        ) : null}
      </React.Fragment>
    );
  };

  renderCantDoInstallment = () => {
    return (
      <React.Fragment>
        <img
          alt="cancel vignette"
          className="main-icons"
          src={require('assets/part.svg')}
          style={{ opacity: 0.5 }}
        />
        <p className="payment" style={{ opacity: 0.5 }}>
          <FormattedMessage id="extend.step.payment.choice.installment" />
        </p>
        <p className="no-installment">
          <FormattedMessage id="extend.instllament.no.installment" />
        </p>
      </React.Fragment>
    );
  };
  render() {
    return (
      <StepCard
        id="extend.step.personal.title"
        steps={7}
        current={5}
        className="payment-choice-extend"
      >
        <Title id="extend.step.payment.choice.title" />
        <Row type="flex" justify="center">
          <Col xs={24} lg={8} md={8} sm={24} className="align-payment-center">
            <img
              alt="renew vignette"
              className="main-icons"
              src={require('assets/full.svg')}
              onClick={() =>
                this.props.updateRoute(
                  EXTEND_VIGNETTE_ROUTE.FULL_PAYMENT_AGREEMENT,
                )
              }
            />
            <p className="payment">
              <FormattedMessage id="extend.step.payment.choice.full" />
            </p>
            <p className="amount">&#8364;&nbsp;{this.getVignetCost()}</p>
          </Col>
          <Col lg={2} className="img-border-divider"></Col>
          <Col xs={24} lg={8} md={8} sm={24} className="align-payment-center">
            {this.canClickInstallment()
              ? this.renderInstallment()
              : this.renderCantDoInstallment()}
          </Col>
        </Row>
        <Row gutter={24} type="flex" justify="center">
          {this.renderSuccessMessage()}
        </Row>
      </StepCard>
    );
  }
}

PaymentChoice.propTypes = {
  isloading: PropTypes.bool,
  goToActivateVignette: PropTypes.func.isRequired,
  goToExtendVignette: PropTypes.func.isRequired,
};

export default PaymentChoice;
